import { createStyles } from './utils';

export const styles = createStyles({
    header: {
        background: 'linear-gradient(360deg, rgb(113 113 113) 0%, rgb(73 52 52) 100%)' /* Gradient background */
    },
    flexDisplay: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    streamersContainer: {
        alignItems: 'start',
        border: '1px solid #545454',
        padding: '5px',
        borderRadius: '5px',
        boxShadow: '0 4px 5px rgba(24, 23, 23, 0.1)'
    },
    innerContainer: {
        background: 'linear-gradient(360deg, rgb(90, 85, 85) 0%, rgb(80 80 80) 100%)',
        padding: '5px'
    },
    streamerTitle:{
        textAlign: 'start',
        margin: '10px',
        color: '#000'
    },
    innerTitle: {
        color: '#f13939'
    },
    streamerParagraph:{    
        fontSize: 'medium',
        color: '#fff ',
        textAlign: 'start',
        margin: '10px',
    }
});