const primary = '#372a2b';
// const secondary = '#F9F7F1'
const tertiary = 'white';
const fontFamily = 'Arial';
export const customTheme = {
  token: {
    colorPrimary: primary,
    // colorBgBase : secondary,
    colorBgContainer: tertiary,
    fontFamily: fontFamily,
    borderRadius: 5,
  },
  // Wants to change the styling of any specific component, then use method mentioned below
  components: {
  },
};
