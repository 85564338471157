import React from 'react';
import { Result, Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title, Text } = Typography;

const NotFoundPage: React.FC = () => {
    const navigate = useNavigate();

    const goHome = () => {
        navigate('/');
    };

    return (
        <div style={{ textAlign: 'center', padding: '50px', backgroundColor: '#f0f2f5', marginTop: '10px' }}>
            <Result
                status="404"
                title={
                    <Title level={1} style={{ fontSize: '64px', color: '#001529' }}>
                        404
                    </Title>
                }
                subTitle={
                    <Text type="secondary" style={{ fontSize: '20px' }}>
                        Oops! The page you are looking for does not exist.
                    </Text>
                }
                extra={
                    <Button type="primary" size="large" onClick={goHome}>
                        Go Back Home
                    </Button>
                }
            />
        </div>
    );
};

export default NotFoundPage;
