import React from 'react';
// import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import './index.css'
import { LandingPage } from '../../components/LandingPage';

export default function MainDescription() {
    // const matches = useMediaQuery('(min-width: 768px)');

    return (
        <LandingPage />
    );
};