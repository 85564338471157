import React, { useEffect, useState } from 'react';
import { Anchor, Drawer, Button } from 'antd';
import './index.css';
import { MenuOutlined } from '@ant-design/icons';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import { styles } from '../../styles';

const { Link } = Anchor;

export const HeaderComponent = () => {
  const matches = useMediaQuery('(min-width: 768px)');
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const [targetOffset, setTargetOffset] = useState<number | undefined>(
    undefined,
  );
  useEffect(() => {
    setTargetOffset(window.innerHeight / 2);
  }, []);

  return (
    <div className="container-fluid">
      <div className="header">
      <div className="mobileVisible">
          <Button type="primary" onClick={showDrawer}>
          <MenuOutlined />
          </Button>
          <Drawer
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
          >
            <Anchor targetOffset={targetOffset}>
              <Link href="#home" title="Home" />
              <Link href="#feature" title="Streams" />
              <Link href="#works" title="How it works" />
              <Link href="#about" title="About" />
              <Link href="#faq" title="FAQ" />
              {/* <Link href="#pricing" title="Pricing" />
              <Link href="#contact" title="Contact" /> */}
            </Anchor>
          </Drawer>
        </div>
        <div className="logo" style={styles.flexDisplay}>
          {matches && <img style={{width: 30, marginRight: '10px',marginTop:'-2px'}} src="https://d22vy4ldq8bjtm.cloudfront.net/incite-tube/logo.webp" alt="Website-logo" />}
          <a href="/">Stream<span className='parallelogram'>east  </span><span style={{color:'#400303'}}>app</span></a>
        </div>
        <div className="mobileHidden">
          <Anchor targetOffset={targetOffset}>
            <Link className='nav-link' href="#home" title="Home" />
            <Link className='nav-link' href="#feature" title="Streams" />
            <Link className='nav-link' href="#works" title="How it works" />
            <Link className='nav-link' href="#about" title="About" />
            <Link className='nav-link' href="#faq" title="FAQ" />
            {/* <Link className='nav-link' href="#pricing" title="Pricing" /> */}
            {/* <Link className='nav-link' href="#contact" title="Contact" /> */}
          </Anchor>
        </div>
      </div>
    </div>
  );
};
