import React from 'react';
import { Collapse, Row, Col } from 'antd';
const { Panel } = Collapse;

export const FAQ = () => {
  return (
    <Row id="main-faq" gutter={24} className='flex-display'>
      <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 22 }}>
        <div className="block faqBlock">
          <div className="container-fluid">
            <div className="titleHolder">
              <a className="block-anchor" id="faq" href="#faq">
                Pseudo anchor
              </a>
              <h2>Frequently Asked Questions</h2>
              <p>
                Following are the most asked queries and responses to help you the the service and guidance on how to use free live streaming platform towatch live sports and soccer streams.
              </p>
            </div>
            <Collapse defaultActiveKey={['1']}>
              <Panel header="Who makes to the top of the list?" key="1">
                <p>
                  Streamer with high quality, uninturrupted, minimal ads, user prefered, makes top of the list.            
                </p>
              </Panel>
              <Panel header="Does it provide multiple streamer options?" key="2">
                <p>
                  Yes, this platform offers you choose stream from alist of choices.            
                </p>
              </Panel>
              <Panel style={{color: 'blue'}} header="How do I submit my streaming platform?" key="3">
                <p>
                  Submit you site on the emailgiven in the <a href="#feature"> How it Works </a> section. 
                </p>
              </Panel>
              <Panel header="What does Stream East offer to its users, Are these free sites?" key="4">
                <p>
                  Yes, Stream East is a network of free platforms that do not require users to sign up in order to access the content. There are no subscription fees when getting the live sports streams, and there are no other concealed charges either. This makes these options very suitable for any sports enthusiasts who do not wish to spend any money whilst watching their favourite games.
                </p>
              </Panel>
              <Panel header="Is it necessary to register to be able to use StreamEast Platforms?" key="5">
                <p>
                  No, it is not necessary to register to use StreamEast Applications, so the service is completely free. This can make it easier for the users to immediately begin steaming without any annoying hurdles.
                </p>
              </Panel>
              <Panel header="Which sports are there on StreamEast App?" key="6">
                <p>
                  StreamEast has multiple sports like soccer/football, NFL, NBA, tennis, cricket, rugby, baseball, and any more. It is a very inclusive platform for most sports and some specific ones to make sure that the spectators of all the sports disciplines can watch the live streams of the event.
                </p>
              </Panel>
              <Panel header="Is StreamEast safe to use?" key="7">
                <p>
                  As mentioned earlier, StreamEast platforms are more safe than any paid platform. Why? because, they do not do not store any users data. They just provide service. These platform just rely on ads tosupport their server cost. 
                </p>
              </Panel>
              <Panel header="What can I do to enhance my streaming on StreamEast Platforms?" key="8">
                <p>
                  It is recommended to open the communication prompt on a device with a stable and fast Internet connection and use a trusted browser, and do not neglect the update of the application.
                </p>
              </Panel>
              <Panel header="Can I watch on multiple devices?" key="9">
                <p>
                  Yes, Stream East App streamers list can be accessed on all kinds of gadgets like smart phone, tablets, desktops, and laptops. This enables the users to have live sports streams in all their channels of interest.
                </p>
              </Panel>
              <Panel header="What should I do when the streaming is frozen and slow, that is, I have buffering problems?" key="10">
                <p>
                  You just need to refresh the page to continue the stream. Additionally, you can secure your cable connection, close other programs from running in the background and possibly even boost your internet speed by changing to a higher package. It was also noted that clearing the browser’s cache often is also useful in enhancing the streaming quality.
                </p>
              </Panel>
            </Collapse>
            {/* <div className="quickSupport">
              <h2>Get In Touch</h2>
              <p>
                
              </p>
              <Button type="primary" size="large">
                <i className="fas fa-envelope"></i> Contact: streamwiz@gmail.com
              </Button>
            </div> */}
          </div>
        </div>
      </Col>
    </Row>
  );
};
