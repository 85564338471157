import React from 'react';
import { 
  Carousel,
} from 'antd';
import { useMediaQuery } from '../../hooks/MediaQuery.hook';
import { HOME_CONTENT } from '../../utils/constant';

export const Home = () => {
  const matches = useMediaQuery('(min-width: 768px)');

  return (
    <div id="home" className='heroBlock'>
      <Carousel>
        {HOME_CONTENT.map((item, index) => {
          return (
            <div key={index} className="container-fluid">
              <div className="content">
                {<h3>{item.title}</h3>}
                <p style={{margin: '10px', fontSize: !matches ? '14px': ''}}>{item.content}</p>
              </div>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
